export default [
  
{
path: "/entity/lieferant",
meta: {
  name: "Lieferant View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/Lieferant.vue`)
},

{
path: "/entity/test",
meta: {
  name: "Test View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/Test.vue`)
},

{
path: "/entity/baseEntity",
meta: {
  name: "BaseEntity View Entity",
  isAuthenticated: true,
  roles: ['ais_admin']
},
component: () => import(`@/views/entity/BaseEntity.vue`)
},

//end here
];
